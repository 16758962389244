@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --global-color-primary: 197, 255, 85;
  scroll-behavior: smooth;
}


/* width */

*::-webkit-scrollbar {
	width: 13px;
	height: 13px;
}


/* Track */

*::-webkit-scrollbar-track {
	border: 3px solid black;
	border-radius: 10px;
}


/* Handle */

*::-webkit-scrollbar-thumb {
	background: rgb(var(--global-color-primary));
	border-radius: 10px;
	border: 3px solid black;
}


/* Handle on hover */

*::-webkit-scrollbar-thumb:hover {
	background: rgb(var(--global-color-primary));
}

body {
  font-family: "Urbanist", sans-serif;
  background-color: rgba(35, 31, 32, 0.93);
  color: #fff;
}

.urbanist {
  font-family: "Urbanist", sans-serif;
}


/* Stili per titoli */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  line-height: 1em;
  color: #fff;
  font-family: "Urbanist", sans-serif;
}

h2 {
  font-size: 72px;
}

h3 {
  font-size: 48px;
}


/* Stili per paragrafi */

p {
  margin-bottom: 1rem;
  font-size: 18px;
  font-family: "Urbanist", sans-serif;
}

span {
  font-family: "Urbanist", sans-serif;
}


/* Stili per link */

a {
  color: #c5ff55;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}


/* Stili per chat */

.msgClass ol, ul {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

.msgClass ol {
  list-style-type: decimal;
}

.msgClass ul {
  list-style-type: disc;
  padding-left:15px;
}

.msgClass li {
  margin-bottom: 0px;
}

.msgClass p {
  margin-bottom: 0px;
  font-size:16px;
}

.msgClass h3 {
  font-size:25px;
  font-weight: 700;
  margin-bottom: 0px;
}

.msgClass ol li p {
  display: inline;
  margin: 0;
}

.notification-badge {
  position: absolute;
  top: -15px;
  right: -5px;
  background-color: #C5FF55;
  color: #000;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 10px;
}

/* Altri stili globali */

.no-p-margin p{
  margin:0;
}

.bg-black-l {
  background-color: #191919;
}

.list-p li {
  list-style-type: disc;
  margin-bottom: 10px;
}

.topIcon {
  position: absolute;
  right: 2%;
  top: 5%;
  font-size: 24px;
  font-weight: 200;
}

.topIcon:hover {
  cursor: pointer;
}

.CatPill {
  background-color: #C5FF55;
  border-radius: 50px;
  color:#191919;
  padding:8px 16px;
  font-weight:600;
}

.Mui-disabled {
  color: #C5FF55 !important;
}

.btn {
  padding:8px 16px;
  background-color: #9ecc44;
  color:#000;
}


/* paginazione */

.pagination button {
  color:#fff;
  font-weight:700;
}

.pagination button[aria-current="true"] {
  background-color:#C5FF55;
  color:#191919;
}


/* header */

.header {
  background-color: #333;
  padding: 10px 20px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.1em;
}

.nav-links a:hover {
  text-decoration: underline;
}

.btn-link {
  padding: 8px 16px;
  border: 1px solid #c5ff55;
  border-radius: 8px;
  background-color: rgba(197, 255, 85, 0.25);
}

.btn-link:hover {
  text-decoration: none !important;
  background-color: rgba(197, 255, 85, 0.65);
  transition: ease-out 0.5s;
}


/* Sidebar */

.SidebarMenuList {
  padding-left: 25px;
}

.SidebarMenuList span {
  color: #fff;
  font-family: "Urbanist", sans-serif;
  font-weight: 700;
}

.MuiList-root, .MuiDataGrid-filterForm, .MuiDataGrid-panelWrapper {
  background-color: #191919;
  color: white;
}

.MuiListItemIcon-root, 
.MuiIconButton-root, 
.MuiFormLabel-root, 
.MuiInput-root, 
.MuiSelect-icon, 
.MuiOutlinedInput-root,
.MuiInputAdornment-root,
.MuiTablePagination-toolbar {
  color: white !important;
}

.MuiInput-root::before, .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}


/* Questions Dialog */

.list-disc-q {
  list-style-type: none !important;    
}

.list-disc-q li {
  background-color: #1E1E1E;
  padding:12px 25px;
  margin:10px;
  border-radius: 15px;
  border:1px solid #C5FF55;
}

.list-disc-q button {
  color:#C5FF55;
  font-weight:700;
  font-size:18px;
}

.css-levciy-MuiTablePagination-displayedRows {
  margin: 0 !important;
}

.MuiFormLabel-root {
  background-color: #191919;
}

.Mui-focused {
  border: 0;
}

.MuiOutlinedInput-notchedOutline {
  border: 1px
}